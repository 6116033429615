import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'

export default function Template({ data }) {
    const post = data.markdownRemark
    const image = getImage(post.frontmatter.coverImage)
    const c1 = post.frontmatter.colors[0];
    const c2 = post.frontmatter.colors[1];

    return (
        <Layout>
            <SEO title={post.frontmatter.name} />
            {post.frontmatter.coverImage ? <GatsbyImage image={image} alt='Cover Image' className="col-span-5 -mx-10 -mt-24 md:-mx-6 md:mt-0" style={{ 'maxHeight': '75vh', 'minHeight': '45vh' }} /> : null}
            <div className={"bg-" + c2 + "-dark md:bg-white-lightest fixed md:sticky mt-12 bottom-0 md:-ml-6 md:pl-12 left-0 w-full md:top-4 z-50 md:z-0"}>
                <Link to='/'>
                        <h6 className={"py-6 md:py-0 text-center font-bold md:text-left text-" + c1 + "-light md:text-" + c2 + "-default md:hover:text-" + c2 + "-dark transition-colors duration-100"}>{"< Back"}</h6>
                </Link>
            </div>
            {post.frontmatter.name ? <h6 className='mt-12 font-bold col-span-5 col-start-1 md:col-start-2 md:col-span-3'>{post.frontmatter.name}</h6> : null}
            {post.frontmatter.title ? <h1 className='col-span-5 mt-2 col-start-1 md:col-start-2 md:col-span-3'>{post.frontmatter.title}</h1> : null}
            {post.frontmatter.intro ? <h5 className='col-span-5 mt-6 mb-2 col-start-1 leading-8 md:col-start-2 md:col-span-3'>{post.frontmatter.intro}</h5> : null}
            {post.frontmatter.tags ? <h6 className='col-span-5 mt-4 mb-2 col-start-1 md:col-start-2 md:col-span-3'>{post.frontmatter.tags.map(entry => entry).join(' | ')}</h6> : null}
            {post.frontmatter.tldr ? 
                <div className={'col-span-5 mt-4 mb-2 col-start-1 md:col-start-2 md:col-span-3 -mx-6 px-12 py-10 bg-' + c1 + '-lightest '}>
                    <h3 className={'text-' + c2 + '-dark mb-2'}>TL;DR</h3>
                    <ul>
                        {post.frontmatter.tldr.map(entry => <li className={'list-disc mx-4 mb-2 text-' + c2 + '-dark'}><h5 className={'inline text-' + c2 + '-dark'}>{entry}</h5></li>)}
                    </ul>
                </div> 
            : null}
            <div dangerouslySetInnerHTML={{ __html: post.html }} className="col-span-5 col-start-1 grid grid-cols-5 gap-x-6" />
        </Layout>
    )
}

export const projectQuery = graphql`
    query ProjectByPath($path: String!) {
        markdownRemark(frontmatter: {path: {eq: $path}}){
            html 
            frontmatter {
                path
                title
                name
                intro
                tags
                tldr
                colors
                coverImage {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1350
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
    }
`